import * as PropTypes from "prop-types"
import { useT } from "react-polyglot-hooks"
import styled from "styled-components"
import Typography from "components/styled/Typography"
import LandingSeo from "components/SEO/LandingSeo"
import HeaderButtons from "components/landing/HeaderButtons"

const Wrapper = styled.div`
  min-height: 580px;
  margin-top: -100px;
`

const Background = styled.div`
  background: white;
  height: 480px;
  padding-top: 100px;
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: 1230px;
  }
`

const Content = styled.div`
  padding: 0 25px;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  @media ${({ theme }) => theme.breakpoints.sm} {
    .home & {
      padding-top: 17px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-top: 0;
    text-align: left;
    max-width: 894px;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-top: 0;
    text-align: left;
    max-width: 1230px;
  }
`

const Title = styled(Typography)`
  margin: 5px 0;
  font-size: 24px;
  line-height: 28px;
  span {
    display: block;
  }
  @media (min-width: 340px) {
    font-size: 28px;
    line-height: 32px;
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 32px;
    line-height: 38px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 36px;
    line-height: 42px;
  }
`

const Subtitle = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    font-size: 30px;
    line-height: 36px;
  }
`

const Image = styled.img`
  display: block;
  height: auto;
  width: 375px;
  margin: 0 auto;
  .home & {
    margin-top: 50px;
  }
  .company & {
    margin-top: -50px;
  }
  @media ${({ theme }) => theme.breakpoints.xs} {
    .employee &,
    .freelance &,
    .company & {
      margin-top: 50px;
    }
    .home & {
      min-width: 400px;
      width: 400px;
      margin-top: 75px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    min-width: 638px;
    width: 638px;

    .home & {
      min-width: 578px;
      width: 578px;
      margin-top: 0;
    }
    .employee & {
      margin-top: -25px;
    }
    .freelance & {
      margin-top: -35px;
    }
    .company & {
      margin-top: -75px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    position: absolute;
    bottom: -100px;
    right: 0;
    .home & {
      width: 634px;
      min-width: 634px;
      margin: 0;
    }
    .employee & {
      width: 759px;
      right: 25px;
      margin-top: 0;
    }
    .freelance & {
      width: 762px;
      bottom: -50px;
    }
    .company & {
      width: 796px;
      bottom: -50px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    .home & {
      width: 839px;
      min-width: 839px;
    }
    .employee & {
      width: 972px;
      min-width: 972px;
      right: -55px;
      bottom: -60px;
    }
    .freelance & {
      width: 961px;
      min-width: 961px;
      bottom: -100px;
      right: -75px;
    }
    .company & {
      width: 955px;
      min-width: 955px;
      right: -75px;
    }
  }
`

const TextBlock = styled.div`
  margin: 0 auto 10px;
  max-width: 668px;
  .home & {
    margin: 0 auto 35px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    margin: 0 0 10px;
    .home & {
      margin: 0 0 35px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    max-width: 620px;
  }
`

export default function Header({ landing, image }) {
  const t = useT()
  const isHome = landing === "home"
  const backgroundUrl = `${process.env.NEXT_PUBLIC_CLOUDINARY}w_auto,f_auto,q_auto,e_trim/static/${landing}/${image}`

  return (
    <>
      <LandingSeo landing={landing} />
      <Wrapper className={landing}>
        <Background>
          <Container>
            <Content className={landing}>
              <TextBlock>
                <Title variant="title1" weight="bold" space={isHome}>
                  {t(`${landing}.title`)}
                  {isHome && <span>{t(`${landing}.subtitle`)}</span>}
                </Title>
                {!isHome && (
                  <Subtitle variant="title2" space el="p">
                    {t(`${landing}.subtitle`)}
                  </Subtitle>
                )}
              </TextBlock>
              <HeaderButtons page={landing} />
            </Content>
            <picture>
              <source
                srcSet={`${backgroundUrl}-sm,
                    ${backgroundUrl}-sm_2x 2x,
                    ${backgroundUrl}-sm_3x 3x`}
                media="(max-width: 374px)"
              />
              <source
                srcSet={`${backgroundUrl}-md,
                    ${backgroundUrl}-md_2x 2x,
                    ${backgroundUrl}-md_3x 3x`}
                media="(max-width: 991px)"
              />
              <source
                srcSet={`${backgroundUrl}-lg,
                    ${backgroundUrl}-lg_2x 2x,
                    ${backgroundUrl}-lg_3x 3x`}
                media="(max-width: 1200px)"
              />
              <source
                srcSet={`${backgroundUrl}-xl,
                    ${backgroundUrl}-xl_2x 2x,
                    ${backgroundUrl}-xl_3x 3x`}
              />
              <Image
                alt={t(isHome ? "home.header_alt" : `${landing}.header-alt`, { _: `Renting ${landing}` })}
                src={`${backgroundUrl}-md`}
              />
            </picture>
          </Container>
        </Background>
      </Wrapper>
    </>
  )
}

Header.defaultProps = {
  image: "header"
}

Header.propTypes = {
  landing: PropTypes.string.isRequired,
  image: PropTypes.string
}
